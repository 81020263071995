import React, { useContext } from "react";
import { columnCategories } from "../utils/columns";
import { useDispatch, useSelector } from "react-redux";
import { updateApplicationData, updateData, updateSalesForceData } from "../redux/actions/appActions";
import { getColumnFilterCookies } from "../utils/cookies";
import { AppContext, TableFilterContext } from "../App";
import { refreshTaggingFields } from "../components/Table";

export default function useAggregation() {
  const { duplicateData, duplicateSalesforceData, columns } = useSelector((state) => state.app);
  const { tableRef, tableRefTwo, npsTableRef, npsTableRefTwo } = useContext(TableFilterContext);
  const { setCurrentPage, setCurrentPageTwo, pageSize, rowSelectForm } = useContext(AppContext);
  const dispatch = useDispatch();

  const applyFilters = React.useCallback((data, columns, category) => {
    let filterConfiguration = null;
    if (category === columnCategories.PRODUCT_DATA || columnCategories.NPS_TAGGING_DATA) {
      filterConfiguration = getColumnFilterCookies(1);
    } else {
      filterConfiguration = getColumnFilterCookies(2);
    }
    if (!filterConfiguration) {
      return data;
    }

    let filteredCols = Object.keys(filterConfiguration);
    filteredCols = columns.filter((col) => filteredCols.includes(col.dataIndex));

    data = data.filter((row) => {
      let match = true;
      filteredCols.forEach((col) => {
        if (filterConfiguration[col.dataIndex].length > 0) {
          match &= !filterConfiguration[col.dataIndex].includes(row[col.dataIndex]);
        }
      });
      return match;
    });

    return data;
  }, []);

  const aggregate = React.useCallback((data, cols) => {
    let aggregatedData = [];
    const visibleColIndices = cols.reduce((acc, col, idx) => {
      if (col.visibility) {
        acc.push(col.dataIndex);
      }
      return acc;
    }, []);
    if (data && data.length)
      data.forEach((row) => {
        const isMatch = aggregatedData.some((aggRow) => {
          const visibleColValuesMatch = visibleColIndices.every((col) => {
            return row[col] === aggRow[col];
          });
          return visibleColValuesMatch;
        });
        if (!isMatch) {
          const visibleRowData = {};
          visibleColIndices.forEach((col) => {
            visibleRowData[col] = row[col];
          });
          aggregatedData.push(row);
        }
      });

    return aggregatedData;
  }, []);

  const resetFilters = React.useCallback(
    (data) => {
      if (tableRef) tableRef.reset(data, false);
      if (npsTableRef) npsTableRef.reset(data, false);
      if (tableRefTwo) tableRefTwo.reset(data, false);
      if (npsTableRefTwo) npsTableRefTwo.reset(data, false);
    },
    [tableRef, npsTableRef, tableRefTwo, npsTableRefTwo]
  );

  const resetPageAndRefresh = React.useCallback(
    (newData, category) => {
      if (category === columnCategories.PRODUCT_DATA || category === columnCategories.NPS_TAGGING_DATA) {
        // table => 1
        setCurrentPage(1);
        refreshTaggingFields(rowSelectForm, newData, pageSize, 1, 1);
      } else if (category === columnCategories.PRODUCT_CUSTOMER_DATA || category === columnCategories.APPLICATION_TAGGING) {
        // table => 2
        setCurrentPageTwo(1);
        refreshTaggingFields(rowSelectForm, newData, pageSize, 1, 2);
      } else if (category === columnCategories.SFDC_COLUMNS) {
        // table => 1
        setCurrentPage(1);
        refreshTaggingFields(rowSelectForm, newData, pageSize, 1, 1);
      }
    },
    [pageSize, rowSelectForm, setCurrentPage, setCurrentPageTwo]
  );

  const aggregateData = React.useCallback(
    async (columns, category, t_data = null) => {
      if (category === columnCategories.NPS_TAGGING_DATA || category === columnCategories.APPLICATION_TAGGING) return;

      let _data = [];
      if (t_data != null) {
        _data = t_data;
      } else if (category === columnCategories.SFDC_COLUMNS) {
        _data = duplicateSalesforceData;
      } else {
        _data = duplicateData;
      }

      const aggregatedData = aggregate(_data, columns);
      const filteredData = applyFilters(aggregatedData, columns, category);
      resetPageAndRefresh(filteredData, category);
      resetFilters(filteredData);

      if (category === columnCategories.PRODUCT_DATA || category === columnCategories.NPS_TAGGING_DATA) {
        dispatch(updateData(filteredData));
      } else if (category === columnCategories.PRODUCT_CUSTOMER_DATA || category === columnCategories.APPLICATION_TAGGING) {
        dispatch(updateApplicationData(filteredData));
      } else if (category === columnCategories.SFDC_COLUMNS) {
        dispatch(updateSalesForceData(filteredData));
      }
    },
    [aggregate, applyFilters, dispatch, duplicateData, duplicateSalesforceData, resetFilters, resetPageAndRefresh]
  );

  const aggregateProductData = React.useCallback(
    (data) => {
      aggregateData(columns[columnCategories.PRODUCT_DATA], columnCategories.PRODUCT_DATA, data);
    },
    [aggregateData, columns]
  );

  const aggregateProductCustomerData = React.useCallback(
    (data) => {
      aggregateData(columns[columnCategories.PRODUCT_CUSTOMER_DATA], columnCategories.PRODUCT_CUSTOMER_DATA, data);
    },
    [aggregateData, columns]
  );

  const aggregateSalesforceDataOnLoad = React.useCallback(
    (data) => {
      aggregateData(columns[columnCategories.SFDC_COLUMNS], columnCategories.SFDC_COLUMNS, data);
    },
    [aggregateData, columns]
  );

  const aggregateSAPDataOnLoad = React.useCallback(
    (data) => {
      aggregateProductData(data);
      aggregateProductCustomerData(data);
    },
    [aggregateProductData, aggregateProductCustomerData]
  );

  return { aggregateData, aggregateSAPDataOnLoad, aggregateSalesforceDataOnLoad };
}
