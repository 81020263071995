import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function ErrorPage({ setFilterDrawerOpen }) {
  const { filters } = useSelector((state) => state.app);
  return (
    <div className="w-full bg-white items-center shadow-xl">
      {filters && filters?.hier2 && filters?.hier2.length === 0 ? (
        <Result status="500" title="500" subTitle="Application is under maintenance, Please visit the application after some time." />
      ) : (
        <Result
          status="404"
          subTitle="There is no data available for tagging based on your filter selections. \n please check the filters and try again."
          extra={
            <Button
              onClick={() => {
                setFilterDrawerOpen(true);
              }}
              type="default"
              icon={<FilterOutlined />}
            >
              Change Filters
            </Button>
          }
        />
      )}
    </div>
  );
}

export function AppError() {
  return (
    <Result
      status="404"
      subTitle="Sorry, Something went wrong. please try again."
      extra={
        <Button
          onClick={() => {
            window.location.reload();
          }}
          type="default"
          icon={<ReloadOutlined />}
        >
          Refresh
        </Button>
      }
    />
  );
}

export function AccessNotFound({ appAccess = false }) {
  const navigate = useNavigate();
  return (
    <Result
      status="403"
      title="403"
      subTitle={`Sorry, you are not authorized to access this ${appAccess ? "Application" : "page"}.`}
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate("/");
          }}
        >
          Back Home
        </Button>
      }
    />
  );
}
