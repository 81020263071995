import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TableFilter from "react-table-filter";
import "react-table-filter/lib/styles.css";
import {
  updateSAPRow,
  updateSalesforceRow,
  updateProdCustRow,
} from "../../redux/actions/appActions";
import { refreshTaggingFields } from "../Table";
import { getColumnFilterCookies } from "../../utils/cookies";
import { COLUMN } from "../../utils/columns";
import ColumnNameRenderer from "../ColumnNameRenderer";
import { usePage } from "../../hooks/usePage";
import TaggingRow from "./TaggingRow";
import useFilterUtils from "../../hooks/useFilterUtils";

function NPSTable({
  columns,
  data,
  form,
  pageSize,
  currentPage,
  setRowsToBeUpdated,
  table,
  updates,
  setUpdates,
  setCurrentPage,
  setTableRef,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const page = usePage();
  const { onFilterChange } = useFilterUtils();

  const validateTagging = (row, rowIndex) => {
    /*      
      the same material cannot be tagged as NPS and application for the same year. but it can be tagged as NPS for one year and application for another year.
    */
    if (
      row &&
      row[COLUMN.NPSYear] &&
      parseInt(row[COLUMN.NPSYear]) === parseInt(form.getFieldValue(`${COLUMN.NPSYear}-${table}-${rowIndex}`))
    ) {
      if (row[COLUMN.QualifyAsApplication] === "YES" && form.getFieldValue(`${COLUMN.QualifyAsNPS}-${table}-${rowIndex}`) === "YES") {
        form.setFields([{ name: `${COLUMN.QualifyAsNPS}-${table}-${rowIndex}`, errors: [""] }]);
        return false;
      } else {
        form.setFields([{ name: `${COLUMN.QualifyAsNPS}-${table}-${rowIndex}`, errors: null }]);
      }

      if (row[COLUMN.QualifyAsNPS] === "YES" && form.getFieldValue(`${COLUMN.QualifyAsApplication}-${table}-${rowIndex}`) === "YES") {
        form.setFields([{ name: `${COLUMN.QualifyAsApplication}-${table}-${rowIndex}`, errors: [""] }]);
        return false;
      } else {
        form.setFields([{ name: `${COLUMN.QualifyAsApplication}-${table}-${rowIndex}`, errors: null }]);
      }
    } else {
      form.setFields([{ name: `${COLUMN.QualifyAsNPS}-${table}-${rowIndex}`, errors: null }]);
      form.setFields([{ name: `${COLUMN.QualifyAsApplication}-${table}-${rowIndex}`, errors: null }]);
    }
    return true;
  };
  const addToUpdatedRowsList = (rowIndex, HashKey, setRowsToBeUpdated, record, updates, form, setUpdates) => {
    if (!validateTagging(record, rowIndex)) {
      Modal.warning({
        title: "Error",
        content: "The same material cannot be tagged as NPS and Application for the same year.",
      });
      return;
    }
    setRowsToBeUpdated((prevState) => {
      let set = new Set(prevState);
      if (!set.has(rowIndex)) {
        set.add(rowIndex);
        let Checkbox = document.getElementById(`check-row-${table}-${rowIndex}`);
        if (Checkbox) Checkbox.checked = true;
        return Array.from(set);
      } else return prevState;
    });
    let temp = updates;
    if (!temp[`${rowIndex}`]) {
      temp[`${rowIndex}`] = {
        HashKey: HashKey,
        TaggedBy: user.fullname,
      };
    }
    const getValue = (dataIndex) => form.getFieldValue(`${dataIndex}-${table}-${rowIndex}` || temp[`${rowIndex}`][`${dataIndex}`]);

    temp[`${rowIndex}`][COLUMN.QualifyAsNPS] = record[COLUMN.QualifyAsNPS] || temp[`${rowIndex}`][COLUMN.QualifyAsNPS];
    temp[`${rowIndex}`][COLUMN.QualifyAsApplication] =
      record[COLUMN.QualifyAsApplication] || temp[`${rowIndex}`][COLUMN.QualifyAsApplication];

    const setDefaults = () => {
      const yr = getValue(COLUMN.NPSYear) || record["GMIDYear"];
      temp[`${rowIndex}`][COLUMN.NPSYear] = yr;
      form.setFieldsValue({
        [`${COLUMN.NPSYear}-${table}-${rowIndex}`]: yr,
        [`${COLUMN.NPSPercentageNew}-${table}-${rowIndex}`]: getValue(COLUMN.NPSPercentageNew) || 0,
        [`${COLUMN.NPSPercentageRenew}-${table}-${rowIndex}`]: getValue(COLUMN.NPSPercentageRenew) || 0,
      });
    };
    if (table === 1) {
      if (form.getFieldValue(`${COLUMN.QualifyAsNPS}-${table}-${rowIndex}`) === "YES") {
        temp[`${rowIndex}`][COLUMN.QualifyAsApplication] = "NO";
        setDefaults();
      }
    }
    if (table === 2) {
      if (form.getFieldValue(`${COLUMN.QualifyAsApplication}-${table}-${rowIndex}`) === "YES") {
        temp[`${rowIndex}`][COLUMN.QualifyAsNPS] = "NO";
        setDefaults();
      }
    }

    columns.forEach(({ dataIndex }) => {
      temp[`${rowIndex}`][`${dataIndex}`] = getValue(dataIndex);
    });
    temp[`${rowIndex}`]["TaggedBy"] = user.fullname;

    form.setFields([
      { name: `${COLUMN.NPSCriteria}-${table}-${rowIndex}`, errors: null },
      { name: `${COLUMN.NPSPercentageNew}-${table}-${rowIndex}`, errors: null },
      { name: `${COLUMN.NPSPercentageRenew}-${table}-${rowIndex}`, errors: null },
    ]);
    dispatch(setUpdates(temp));
    if (page === "SAP") {
      if (table === 1) dispatch(updateSAPRow(temp[`${rowIndex}`], rowIndex));
      else dispatch(updateProdCustRow(temp[`${rowIndex}`], rowIndex));
    } else {
      dispatch(updateSalesforceRow(temp[`${rowIndex}`], rowIndex));
    }
  };

  const onChangeHandler = (idx, HashKey) => {
    addToUpdatedRowsList(idx, HashKey, setRowsToBeUpdated, data[idx], updates, form, setUpdates);
  };

  const rowIndex = (idx) => idx + (currentPage - 1) * pageSize;

  return data ? (
    <table>
      <thead>
        <TableFilter
          rows={data}
          initialFilters={getColumnFilterCookies(table)}
          onFilterUpdate={(newData, filterConfiguration) => {
            onFilterChange(newData, filterConfiguration, setCurrentPage,table);
            refreshTaggingFields(form, newData, pageSize, currentPage, table);
          }}
          ref={(node) => setTableRef(node)}
        >
          {columns
            .filter((column) => column.visibility)
            .map((column) =>
              column.visibility ? (
                <th
                  key={column.dataIndex}
                  filterkey={column.dataIndex}
                  style={{
                    width: "150px",
                    minWidth: "150px",
                  }}
                >
                  <ColumnNameRenderer dataIndex={column.dataIndex}>{column.title}</ColumnNameRenderer>
                </th>
              ) : null
            )}
        </TableFilter>
      </thead>
      <tbody>
        {data &&
          data
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((row, rindex) => (
              <TaggingRow
                key={rowIndex(rindex)}
                columns={columns}
                rindex={rindex}
                rowIndex={rowIndex}
                row={row}
                table={table}
                onChangeHandler={onChangeHandler}
                form={form}
                updates={updates}
              />
            ))}
      </tbody>
    </table>
  ) : null;
}

export default NPSTable;
