import React, { useContext } from "react";
import { AppContext, TableFilterContext } from "../App";
import { COLUMN } from "../utils/columns";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFilterCookies } from "../utils/cookies";
import {
  setUpdatesOne,
  setUpdatesTwo,
  updateApplicationData,
  updateAppLoading,
  updateData,
  updateDuplicateData,
  updateDuplicateSalesForceData,
  updateNPSTagging,
  updateSalesForceData,
} from "../redux/actions/appActions";
import { usePage } from "./usePage";

export default function useTagging() {
  const {
    settoolbarValues,
    toolbarForm,
    messageApi,
    rowSelectForm,
    rowsToBeUpdated,
    rowsToBeUpdatedForTableTwo,
    salesforceRowsTobeUpdated,
    setSalesforceRowsTobeUpdated,
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
    currentPage,
    currentPageTwo,
    pageSize,
  } = useContext(AppContext);

  const { data, duplicateData, applicationData, salesforceData, duplicateSalesforceData, updatesOne, updatesTwo } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const page = usePage();

  const { tableRef, tableRefTwo, npsTableRef, npsTableRefTwo } = useContext(TableFilterContext);

  const validateNPSTagging = (updates, idx, table) => {
    let npsTagError = false;
    const _data = page === "salesforce" ? salesforceData : table === 1 ? data : applicationData;
    if (
      _data[idx].NPSYear &&
      Number(_data[idx].NPSYear) === Number(updates[idx]?.NPSYear) &&
      ((updates[idx]?.QualifyAsNPS === "YES" && _data[idx].QualifyAsApplication === "YES") ||
        (updates[idx]?.QualifyAsApplication === "YES" && _data[idx].QualifyAsNPS === "YES"))
    ) {
      rowSelectForm.setFields([{ name: `${COLUMN.QualifyAsNPS}-${table}-${idx}`, errors: [""] }]);
      rowSelectForm.setFields([{ name: `${COLUMN.QualifyAsApplication}-${table}-${idx}`, errors: [""] }]);
      npsTagError = true;
    } else {
      rowSelectForm.setFields([{ name: `${COLUMN.QualifyAsNPS}-${table}-${idx}`, errors: null }]);
      rowSelectForm.setFields([{ name: `${COLUMN.QualifyAsApplication}-${table}-${idx}`, errors: null }]);
    }
    return npsTagError;
  };
  const validatePercentageExceeded = (updates, idx, table) => {
    if (updates[idx]?.NPSPercentageNew + updates[idx]?.NPSPercentageRenew > 100) {
      rowSelectForm.setFields([
        { name: `${COLUMN.NPSPercentageNew}-${table}-${idx}`, errors: [""] },
        { name: `${COLUMN.NPSPercentageRenew}-${table}-${idx}`, errors: [""] },
      ]);
      return true;
    } else {
      rowSelectForm.setFields([
        { name: `${COLUMN.NPSPercentageNew}-${table}-${idx}`, errors: null },
        { name: `${COLUMN.NPSPercentageRenew}-${table}-${idx}`, errors: null },
      ]);
    }
    return false;
  };
  const validatePercentageZero = (updates, idx, table) => {
    if (updates[idx]?.NPSPercentageNew === 0 && updates[idx]?.NPSPercentageRenew === 0) {
      rowSelectForm.setFields([
        { name: `${COLUMN.NPSPercentageNew}-${table}-${idx}`, errors: [""] },
        { name: `${COLUMN.NPSPercentageRenew}-${table}-${idx}`, errors: [""] },
      ]);
      return true;
    } else {
      rowSelectForm.setFields([
        { name: `${COLUMN.NPSPercentageNew}-${table}-${idx}`, errors: null },
        { name: `${COLUMN.NPSPercentageRenew}-${table}-${idx}`, errors: null },
      ]);
    }
    return false;
  };
  const validateCriteria = (updates, idx, table) => {
    if ((updates[idx]?.QualifyAsNPS === "YES" || updates[idx]?.QualifyAsApplication === "YES") && !updates[idx]?.NPSCriteria) {
      rowSelectForm.setFields([{ name: `${COLUMN.NPSCriteria}-${table}-${idx}`, errors: [""] }]);
      return true;
    } else {
      rowSelectForm.setFields([{ name: `${COLUMN.NPSCriteria}-${table}-${idx}`, errors: null }]);
    }
    return false;
  };

  const validateNpsYear = (updates, idx, table) => {
    const _data = page === "salesforce" ? salesforceData : table === 1 ? data : applicationData;

    if (updates[idx]?.NPSYear && Number(updates[idx].NPSYear) < Number(_data[idx].GMIDYear)) {
      rowSelectForm.setFields([{ name: `${COLUMN.NPSYear}-${table}-${idx}`, errors: [""] }]);
      return true;
    } else {
      rowSelectForm.setFields([{ name: `${COLUMN.NPSYear}-${table}-${idx}`, errors: null }]);
    }
    return false;
  };

  const validateTagging = (updatedRows, updates, table) => {
    let percentExceeded = false;
    let percentZero = false;
    let isCriteriaError = false;
    let NPSYearError = false;
    let npsTagError = false;
    updatedRows.forEach((idx) => {
      if ((updates[idx] && updates[idx].QualifyAsNPS === "YES") || updates[idx].QualifyAsApplication === "YES") {
        percentExceeded = percentExceeded || validatePercentageExceeded(updates, idx, table);
        percentZero = percentZero || validatePercentageZero(updates, idx, table);
        isCriteriaError = isCriteriaError || validateCriteria(updates, idx, table);
        if (page === "SAP") NPSYearError = NPSYearError || validateNpsYear(updates, idx, table);
        npsTagError = npsTagError || validateNPSTagging(updates, idx, table);
      }
    });

    return [percentExceeded, percentZero, isCriteriaError, NPSYearError, npsTagError];
  };

  const warn = (resOne, resTwo) => {
    const [percentError, percentZero, criteriaError, NPSYearError, npsTagError] = resOne;
    const [percentErrorTwo, percentZeroTwo, criteriaErrorTwo, NPSYearErrorTwo, npsTagErrorTwo] = resTwo;
    Modal.warning({
      title: "Error",
      content: (
        <>
          {(percentError || percentErrorTwo) && <p>* New NPS % + Renew NPS % should not exceed 100%</p>}
          {(criteriaError || criteriaErrorTwo) && <p>* NPS Criteria is required</p>}
          {(NPSYearError || NPSYearErrorTwo) && <p>* NPS Year should not be less than GMID Year</p>}
          {(percentZero || percentZeroTwo) && <p>* New NPS % + Renew NPS % should not be 0</p>}
          {(npsTagError || npsTagErrorTwo) && <p>* The same material cannot be tagged as NPS and Application for the same year.</p>}
        </>
      ),
    });
  };

  //updatedRows is an array of rowIndexes of updated rows
  // updates is an object with key as rowIndex and value as updated values
  const merge = (updatedRows, updates) => {
    if (updatedRows && updatedRows.length) {
      return updatedRows.map((row) => updates[row]);
    }
    return [];
  };
  const mergeUpdates = () => {
    let newRows = [];
    newRows = merge(rowsToBeUpdated, updatesOne);
    newRows = [...newRows, ...merge(rowsToBeUpdatedForTableTwo, updatesTwo)];
    newRows = [...newRows, ...merge(salesforceRowsTobeUpdated, updatesTwo)];
    newRows = newRows.filter((row) => row?.HashKey); // picks nonempty rows
    return newRows;
  };

  const taggingHelper = () => {
    messageApi.open({
      key: "update",
      type: "loading",
      content: "validating rows, Please Hang on...",
      duration: 10,
    });
    const resOne = validateTagging(rowsToBeUpdated, updatesOne, 1);
    const resTwo = validateTagging(rowsToBeUpdatedForTableTwo, updatesTwo, 2);
    if (resOne.includes(true) || resTwo.includes(true)) {
      messageApi.open({
        key: "update",
        type: "error",
        content: "Please enter all the required fields.",
      });
      warn(resOne, resTwo);
      return;
    }
    let rows = mergeUpdates();
    if (rows.length === 0) {
      messageApi.open({
        key: "update",
        type: "warning",
        content: "Please select a row or Enter new values to update",
      });
      return;
    }
    messageApi.open({
      key: "update",
      type: "loading",
      content: "Updating rows, Please Hang on...",
      duration: 1200,
    });
    return rows;
  };
  const isCuurentPage = (table, idx) => {
    const page = table === 1 ? currentPage : currentPageTwo;
    const start = (page - 1) * pageSize;
    const end = page * pageSize;

    if (idx >= start && idx <= end) return true;
    return false;
  };
  const resetFilterData = (data) => {
    tableRef.reset(data, false);
    npsTableRef.reset(data, false);
    tableRefTwo.reset(data, false);
    npsTableRefTwo.reset(data, false);
  };
  const resetSalesforceFilterData = (data) => {
    tableRef.reset(data, false);
    npsTableRef.reset(data, false);
  };
  const submitTagging = async () => {
    const filterCookies = getFilterCookies();
    try {
      let rows = taggingHelper();
      if (!rows) return;
      const res = await updateNPSTagging({ hier2: filterCookies.hier2, rows });
      messageApi.open({
        key: "update",
        type: "success",
        content: "Rows Updated.",
      });
      rowSelectForm.resetFields();
      let _data = data;
      let _duplicateData = JSON.parse(JSON.stringify(duplicateData));
      let _applicationData = applicationData;
      res.forEach((row) => {
        const idx = _data.findIndex((_row) => _row.HashKey === row.HashKey);
        if (idx > -1) {
          _data.splice(idx, 1, row);
        }
        const idx2 = _duplicateData.findIndex((_row) => _row.HashKey === row.HashKey);
        if (idx2 > -1) {
          _duplicateData.splice(idx2, 1, row);
        }
        const idx3 = _applicationData.findIndex((_row) => _row.HashKey === row.HashKey);
        if (idx3 > -1) {
          _applicationData.splice(idx3, 1, row);
        }
      });
      dispatch(updateData(_data));
      dispatch(updateDuplicateData(_duplicateData));
      dispatch(updateApplicationData(_applicationData));
      resetFilterData(_duplicateData);
      dispatch(setUpdatesOne({}));
      dispatch(setUpdatesTwo({}));

      // newRows is the updated rows
      const refreshFileds = (newRows, table) => {
        const formUpdates = rowSelectForm.getFieldsValue();
        newRows.forEach((row) => {
          const idx = data.findIndex((_row) => _row.HashKey === row.HashKey);
          if (idx !== -1 && isCuurentPage(table, idx)) {
            const checkbox = document.getElementById(`check-row-${table}-${idx}`);
            if (checkbox) checkbox.checked = false;
            if (table === 1) formUpdates[`QualifyAsNPS-${table}-${idx}`] = row?.QualifyAsNPS;
            if (table === 2) formUpdates[`QualifyAsApplication-${table}-${idx}`] = row?.QualifyAsApplication;
            formUpdates[`NPSPercentageNew-${table}-${idx}`] = row.NPSPercentageNew;
            formUpdates[`NPSCriteria-${table}-${idx}`] = row.NPSCriteria;
            formUpdates[`NPSPercentageRenew-${table}-${idx}`] = row.NPSPercentageRenew;
            formUpdates[`SustnAdvgedSales-${table}-${idx}`] = row.SustnAdvgedSales;
            formUpdates[`SustnAdvgedSalesPercentage-${table}-${idx}`] = row.SustnAdvgedSalesPercentage;
            formUpdates[`NPSYear-${table}-${idx}`] = row.NPSYear;
            formUpdates[`Project-${table}-${idx}`] = row.Project;
            formUpdates[`Commentary-${table}-${idx}`] = row.Commentary;
          }
        });
        rowSelectForm.setFieldsValue(formUpdates);
      };
      setRowsToBeUpdated([]);
      setRowsToBeUpdatedForTableTwo([]);
      refreshFileds(res, 1); // for  PRODUCT table
      refreshFileds(res, 2); // for PROD/CUSTOMER table
      settoolbarValues(null);
      toolbarForm.resetFields();
      dispatch(updateAppLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(updateAppLoading(false));
      messageApi.open({
        key: "update",
        type: "error",
        content: "Something went wrong, Please try again. ",
      });
    }
  };

  const submitSalesforceTagging = async () => {
    try {
      let rows = taggingHelper();
      if (!rows) return;
      const res = await updateNPSTagging({
        hier2: "ELECTRONICS & INDUSTRIAL",
        hier5: "LIVEO",
        dataType: "salesforce",
        rows,
      });
      messageApi.open({
        key: "update",
        type: "success",
        content: "Rows Updated.",
      });
      let _data = salesforceData;
      let _duplicateData = JSON.parse(JSON.stringify(duplicateSalesforceData));
      res.forEach((row) => {
        const idx = _data.findIndex((_row) => _row.HashKey === row.HashKey);
        if (idx > -1) {
          _data.splice(idx, 1, row);
        }
        const idx2 = _duplicateData.findIndex((_row) => _row.HashKey === row.HashKey);
        if (idx2 > -1) {
          _duplicateData.splice(idx2, 1, row);
        }
      });
      dispatch(updateSalesForceData(_data));
      dispatch(updateDuplicateSalesForceData(_duplicateData));
      resetSalesforceFilterData(_duplicateData);
      dispatch(setUpdatesTwo({}));
      setRowsToBeUpdated([]);
      rowsToBeUpdatedForTableTwo.forEach((row, index) => {
        const checkbox = document.getElementById(`check-row-2-${row}`);
        if (checkbox) checkbox.checked = false;
      });
      setRowsToBeUpdatedForTableTwo([]);
      setSalesforceRowsTobeUpdated([]);
      settoolbarValues(null);
      toolbarForm.resetFields();
    } catch (err) {
      messageApi.open({
        key: "update",
        type: "error",
        content: "Something went wrong, please try again",
      });
    }
  };

  return { submitTagging, submitSalesforceTagging };
}
