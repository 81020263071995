import React from "react";
import { runes } from "runes2";
import TimeStampRenderer from "./TimeStampRenderer";
import { Form, Input, InputNumber, Select } from "antd";
import { COLUMN } from "../../utils/columns";
import { getNpsYearOptions } from "../../utils/monthUtils";
import { useSelector } from "react-redux";

const getDefaultDisabled = (row, table) => {
  if (table === 1) {
    if (row[COLUMN.QualifyAsNPS] === "YES") {
      return false;
    }
  } else if (table === 2) {
    if (row[COLUMN.QualifyAsApplication] === "YES") {
      return false;
    }
  }
  return true;
};

function TaggingRow(props) {
  const { columns, rindex, rowIndex, row, table, onChangeHandler, form } = props;
  const [isDisabled, setIsDisabled] = React.useState(getDefaultDisabled(row, table));
  const [isReqired, setIsRequired] = React.useState(!getDefaultDisabled(row, table));
  const { salesYear } = useSelector((state) => state.selections);
  const { roles } = useSelector((state) => state.auth);
  const isProdViewer = roles && roles.includes("NPS_PROD_VIEWER");

  const isRequiredField = (isColumnRequired) => (isReqired && isColumnRequired ? "required-field" : "");

  const resetRow = () => {
    form.setFieldsValue({
      [`${COLUMN.NPSPercentageNew}-${table}-${rowIndex(rindex)}`]: null,
      [`${COLUMN.NPSPercentageRenew}-${table}-${rowIndex(rindex)}`]: null,
      [`${COLUMN.NPSCriteria}-${table}-${rowIndex(rindex)}`]: null,
      [`${COLUMN.SustnAdvgedSales}-${table}-${rowIndex(rindex)}`]: null,
      [`${COLUMN.SustnAdvgedSalesPercentage}-${table}-${rowIndex(rindex)}`]: null,
      [`${COLUMN.NPSYear}-${table}-${rowIndex(rindex)}`]: null,
      [`${COLUMN.Project}-${table}-${rowIndex(rindex)}`]: null,
      [`${COLUMN.Commentary}-${table}-${rowIndex(rindex)}`]: null,
    });
  };

  const getDropdownOptions = React.useCallback(
    (column) => {
      if (column.dataIndex === "NPSYear") return getNpsYearOptions(salesYear);
      return column?.options;
    },
    [salesYear]
  );

  return (
    <tr key={rowIndex(rindex)} id={`product-data-${rowIndex(rindex)}`}>
      {columns &&
        columns.map((column, index) =>
          column.visibility ? (
            <td key={index} className="px-2">
              {column.type === "dropdown" ? (
                <Form.Item
                  shouldUpdate={true}
                  className={isRequiredField(column?.required)}
                  name={`${column.dataIndex}-${table}-${rowIndex(rindex)}`}
                  initialValue={row[column.dataIndex]}
                  style={{
                    height: 1,
                  }}
                >
                  <Select
                    allowClear
                    className="w-full"
                    disabled={
                      (isDisabled && COLUMN.QualifyAsNPS !== column.dataIndex && COLUMN.QualifyAsApplication !== column.dataIndex) ||
                      isProdViewer
                    }
                    placeholder={column?.placeholder || column.title}
                    options={getDropdownOptions(column)}
                    id={`${column.dataIndex}-${table}-${rowIndex(rindex)}`}
                    onChange={(value) => {
                      if (column.dataIndex === COLUMN.QualifyAsNPS || column.dataIndex === COLUMN.QualifyAsApplication) {
                        if (value === "YES") {
                          setIsDisabled(false);
                          setIsRequired(true);
                        } else {
                          setIsDisabled(true);
                          setIsRequired(false);
                          resetRow();
                        }
                      }
                      onChangeHandler(rowIndex(rindex), row.HashKey);
                    }}
                  />
                </Form.Item>
              ) : column.type === "input" && column?.dataType === "number" ? (
                <Form.Item
                  shouldUpdate={true}
                  name={`${column.dataIndex}-${table}-${rowIndex(rindex)}`}
                  initialValue={row[column.dataIndex]}
                  style={{
                    height: 1,
                  }}
                >
                  <InputNumber
                    allowClear
                    controls={false}
                    step={0}
                    className={`w-full ${isRequiredField(column?.required)}`}
                    disabled={isDisabled || isProdViewer}
                    placeholder={column?.placeholder || column.title}
                    min={0}
                    max={100}
                    id={`${column.dataIndex}-${table}-${rowIndex(rindex)}`}
                    onChange={(value) => {
                      onChangeHandler(rowIndex(rindex), row.HashKey);
                    }}
                  />
                </Form.Item>
              ) : column.type === "input" ? (
                <Form.Item
                  shouldUpdate={true}
                  name={`${column.dataIndex}-${table}-${rowIndex(rindex)}`}
                  initialValue={row[column.dataIndex]}
                  style={{
                    height: 1,
                  }}
                >
                  <Input
                    allowClear
                    id={`${column.dataIndex}-${table}-${rowIndex(rindex)}`}
                    onChange={(event) => {
                      onChangeHandler(rowIndex(rindex), row.HashKey);
                    }}
                    disabled={isDisabled || isProdViewer}
                    placeholder={column?.placeholder || column.title}
                    count={{
                      max: 300,
                      show: true,
                      exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(""),
                    }}
                  />
                </Form.Item>
              ) : column.type === "textView" ? (
                column.dataIndex === "lastmodifiedtimestamp" ? (
                  <TimeStampRenderer timestamp={row[column.dataIndex]} />
                ) : (
                  <span>{row[column.dataIndex]}</span>
                )
              ) : null}
            </td>
          ) : null
        )}
    </tr>
  );
}

export default TaggingRow;
