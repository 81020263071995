import { useDispatch } from "react-redux";
import { setColumnFilterCookies } from "../utils/cookies";
import { usePage } from "./usePage";
import { setUpdatesOne, setUpdatesTwo, updateApplicationData, updateData, updateSalesForceData } from "../redux/actions/appActions";

export default function useFilterUtils() {
  const dispatch = useDispatch();
  const page = usePage();

  const onFilterChange = (newData, filterConfiguration, setCurrentPage, table) => {
    setColumnFilterCookies(filterConfiguration, table);
    if (page === "SAP") {
      if (table === 1) dispatch(updateData(newData));
      else dispatch(updateApplicationData(newData));
    } else dispatch(updateSalesForceData(newData));
    setCurrentPage(1);
    dispatch(setUpdatesOne({}));
    dispatch(setUpdatesTwo({}));
  };

  return { onFilterChange };
}
