import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../config/axiosClient";
import {
  setAppError,
  setUpdatesOne,
  setUpdatesTwo,
  updateAppLoading,
  updateData,
  updateDuplicateData,
  updateDuplicateSalesForceData,
  updateSalesForceData,
} from "../redux/actions/appActions";
import useAggregation from "./useAggregations";
import { useCallback } from "react";

export default function useData() {
  const { hasAccessToApp } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { aggregateSAPDataOnLoad, aggregateSalesforceDataOnLoad } = useAggregation();

  const getData = useCallback(
    async (data) => {
      dispatch(updateData(null));
      dispatch(updateDuplicateData(null));
      dispatch(updateAppLoading(true));
      dispatch(setAppError(null));
      dispatch(setUpdatesOne({}));
      dispatch(setUpdatesTwo({}));

      if (!hasAccessToApp) {
        return null;
      } else {
        await axiosClient
          .post(`/getdata`, data)
          .then((res) => {
            dispatch(updateDuplicateData(res));
            aggregateSAPDataOnLoad(res);
            dispatch(updateAppLoading(false));
          })
          .catch((err) => {
            console.log(err);
            dispatch(setAppError(true));
            dispatch(updateAppLoading(false));
            dispatch(updateData([]));
            dispatch(updateDuplicateData([]));
          });
      }
    },
    [aggregateSAPDataOnLoad, dispatch, hasAccessToApp]
  );

  const getSalesForceData = useCallback(
    async (filters = {}) => {
      dispatch(updateSalesForceData(null));
      dispatch(updateDuplicateSalesForceData(null));
      dispatch(setAppError(null));
      await axiosClient
        .post(`/getsfdc`, filters)
        .then((res) => {
          dispatch(updateDuplicateSalesForceData(res));
          aggregateSalesforceDataOnLoad(res);
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAppError(true));
          dispatch(updateSalesForceData([]));
        });
    },
    [aggregateSalesforceDataOnLoad, dispatch]
  );
  return { getData, getSalesForceData };
}