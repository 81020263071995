import { AutoComplete, Button, Drawer, Form, Select, Space } from "antd";
import React, { memo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFilterCookies, getFilterCookies, updateFilterCookies } from "../utils/cookies";
import Loading from "./Loading";
import {  setAppError } from "../redux/actions/appActions";
import { AppContext } from "../App";
import { useLocation, useNavigate } from "react-router-dom";
import { getMonthOptions, getYearOptions } from "../utils/monthUtils";
import { updateUserSelection } from "../redux/actions/userSelectionActions";
import useData from "../hooks/useData";

function FilterPopup({ open, setOpen }) {
  const { filters } = useSelector((state) => state.app);
  const [cachedFilters, setCachedFilters] = useState(getFilterCookies());
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [businessSelected, setBusinessSelected] = useState(cachedFilters?.hier2);
  const [lobSelected, setLobSelected] = useState(cachedFilters?.hier3);
  const [reportingUnitSelected, setReportingUnitSelected] = useState(cachedFilters?.hier5);
  const { hasSFDCAccess, hasAccessToApp } = useSelector((state) => state.app);
  const monthOptions = getMonthOptions();
  const yearOptions = getYearOptions();
  const [businessoptions, setBusinessOptions] = useState([]);
  const [loboptions, setLobOptions] = useState([]);
  const [reportingUnitOptions, setReprotingUnitOptions] = useState([]);
  const { salesYear } = useSelector((state) => state.selections);
  const { getData } = useData();
  
  const {
    rowSelectForm,
    GMIDMonthLocal,
    setGMIDMonthLocal,
    GMIDYearLocal,
    setGMIDYearLocal,
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
    filterFormDisabled,
    setfilterFormDisabled,
    messageApi,
  } = useContext(AppContext);

  const updateFilters = () => {
    updateFilterCookies(filterForm.getFieldsValue());
  };
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    try {
      if (filters) {
        setBusinessOptions(
          Object.keys(filters)
            .map((value) => {
              return {
                label: value,
                value: value,
              };
            })
            .sort((a, b) => (a.value < b.value ? -1 : 1))
        );
        if (filters && cachedFilters?.hier2) {
          setLobOptions(
            Object.keys(filters[cachedFilters.hier2])
              .map((value) => {
                return {
                  label: value,
                  value: value,
                };
              })
              .sort((a, b) => (a.value < b.value ? -1 : 1))
          );
          setReprotingUnitOptions(filters[cachedFilters.hier2][cachedFilters.hier3]);
        }
      }
    } catch (err) {
      clearFilterCookies();
      dispatch(setAppError(true));
      setOpen(true);
    }
  }, [filters]);

  useEffect(() => {
    setCachedFilters(getFilterCookies());
  }, []);
  useEffect(() => {
    if (location.pathname === "/") setfilterFormDisabled(false);
    else setfilterFormDisabled(true);
  }, [location]);

  return (
    <Drawer
      placement="left"
      rootClassName="filter-drawer"
      open={hasAccessToApp && open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      closeable={false}
      closeIcon={null}
      maskClosable={false}
      destroyOnClose={true}
      width={480}
      mask={false}
      style={{ margin: 0, padding: 0 }}
    >
      <Form
        form={filterForm}
        layout="vertical"
        onFinish={async () => {
          setOpen(false);
          updateFilters();
          dispatch(updateUserSelection({ gmidMonth: GMIDMonthLocal, gmidYear: GMIDYearLocal }));
          const dataSource = filterForm.getFieldValue("dataSource") || "SAP";
          if (dataSource === "SAP") {
            navigate("/");
            const checkBoxes = document.getElementsByClassName("row-select-check");
            for (let i = 0; i < checkBoxes.length; i++) {
              if (checkBoxes[i]) checkBoxes[i].checked = false;
            }
            setRowsToBeUpdated([]);
            setRowsToBeUpdatedForTableTwo([]);
            messageApi.open({
              type: "loading",
              content: "Applying filters, please wait",
              duration: 60,
              key: "filters",
            });
            await getData({
                gmidmonth: GMIDMonthLocal,
                gmidyear: GMIDYearLocal,
                salesyear: salesYear,
                ...filterForm.getFieldsValue(),
              })
              .then((res) => {
                messageApi.open({
                  key: "filters",
                  type: "success",
                  duration: 5,
                  content: "Filters applied",
                });
                rowSelectForm.resetFields();
              })
              .catch((er) => {
                dispatch(setAppError(true));
              });
          } else {
            navigate("/salesforce");
          }
        }}
      >
        <h1 className="bold text-red-600">Welcome to the NPS Tagging App!</h1>
        <span>Please select and confirm the following to continue&nbsp;:</span>
        {filters ? (
          <div className="flex flex-col gap-3">
            <Form.Item
              label={<h4>Business</h4>}
              name="hier2"
              rules={[
                {
                  required: !filterFormDisabled,
                  message: "Business is required",
                },
              ]}
              initialValue={cachedFilters?.hier2}
              style={{ marginBottom: -10 }}
            >
              {businessoptions && businessoptions.length > 0 ? (
                <Select
                  disabled={filterFormDisabled}
                  options={businessoptions}
                  onChange={(value) => {
                    setBusinessSelected(value);
                    filterForm.setFieldValue("hier5", null);
                    filterForm.setFieldValue("hier3", null);
                    setLobOptions(
                      Object.keys(filters[value]).map((value) => {
                        return {
                          label: value,
                          value: value,
                        };
                      })
                    );
                  }}
                  placeholder="Business"
                  style={{ marginTop: -25 }}
                  allowClear
                  filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                />
              ) : null}
            </Form.Item>
            <Form.Item
              label={<h4>Line of Business</h4>}
              name="hier3"
              rules={[
                {
                  required: !filterFormDisabled,
                  message: "Line of Business is required",
                },
              ]}
              initialValue={cachedFilters?.hier3}
              style={{ marginBottom: -5 }}
            >
              <Select
                disabled={filterFormDisabled}
                options={loboptions}
                placeholder="Line of Business"
                style={{ marginTop: -25 }}
                allowClear
                onChange={(value) => {
                  setLobSelected(value);
                  filterForm.setFieldValue("hier5", null);
                  setReprotingUnitOptions(filters[businessSelected][value].sort((a, b) => (a.value < b.value ? -1 : 1)));
                }}
              />
            </Form.Item>
            <Form.Item
              label={<h4>Reporting Unit</h4>}
              name="hier5"
              rules={[
                {
                  required: !filterFormDisabled,
                  message: "Reporting Unit is required",
                },
              ]}
              initialValue={cachedFilters?.hier5}
              style={{ marginBottom: -5 }}
            >
              <Select
                disabled={filterFormDisabled}
                options={reportingUnitOptions}
                placeholder="Reporting Unit"
                style={{ marginTop: -25 }}
                allowClear
                onChange={(value) => setReportingUnitSelected(value)}
              />
            </Form.Item>
            {hasSFDCAccess && reportingUnitSelected === "LIVEO" ? (
              <Form.Item
                label={<h4>Data source</h4>}
                name="dataSource"
                rules={[
                  {
                    required: true,
                    message: "Data source is required",
                  },
                ]}
                initialValue={location.pathname === "/" ? "SAP" : "SFDC"}
                style={{ marginBottom: -5 }}
              >
                <Select
                  options={[
                    {
                      label: "SAP",
                      value: "SAP",
                    },
                    {
                      label: "SFDC",
                      value: "SFDC",
                    },
                  ]}
                  placeholder="Data source"
                  style={{ marginTop: -25 }}
                  allowClear
                  filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                  onChange={(value) => {
                    if (value === "SAP") setfilterFormDisabled(false);
                    else setfilterFormDisabled(true);
                  }}
                />
              </Form.Item>
            ) : null}
            <Form.Item
              label={
                <h4 className="flex flex-row items-center">
                  {!filterFormDisabled ? <span className="text-red-400 text-lg">*</span> : null}
                  &nbsp; Material Created Date{" "}
                </h4>
              }
              rules={[
                {
                  required: !filterFormDisabled,
                },
              ]}
              style={{ marginTop: 0 }}
            >
              <Space direction="horizontal" style={{ marginTop: "-100px" }} className="w-full">
                <span className="w-1/2">
                  &nbsp; GMID Year :
                  <AutoComplete
                    disabled={filterFormDisabled}
                    style={{ width: 225 }}
                    placeholder="Year"
                    options={yearOptions}
                    value={GMIDYearLocal}
                    onChange={(year) => {
                      // rowSelectForm.resetFields();
                      setGMIDYearLocal(year);
                    }}
                  />
                </span>
                <span className="w-1/2">
                  &nbsp;GMID Month:
                  <Select
                    disabled={filterFormDisabled}
                    style={{ width: 225 }}
                    placeholder="Month"
                    value={GMIDMonthLocal}
                    options={monthOptions}
                    onChange={(month) => {
                      // rowSelectForm.resetFields();
                      setGMIDMonthLocal(month);
                    }}
                  />
                </span>
              </Space>
            </Form.Item>
          </div>
        ) : (
          <Loading />
        )}
        <div className="flex flex-row justify-between items-center">
          <span className="text-red-500"> * Required fields</span>
          <Button type="primary" htmlType="submit">
            Apply Filters
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default memo(FilterPopup);
